"use client";

import Link from "next/link";
import styles from "./index.module.scss";
import Image from "next/image";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { ICardMini } from "@/shared/types/types";
import { decodeString } from "@/utils/api/decodeString";
import Stars from "@/shared/components/Stars";
import Actions from "@/shared/components/CardMini/components/Actions";
import Labels from "@/shared/components/CardMini/components/Labels";
import Price from "@/shared/components/CardMini/components/Price";
import Buttons from "@/shared/components/CardMini/components/Buttons";
import { li } from "@/utils/functions/li";
import clsx from "clsx";
import { useRouter } from "next/navigation";
interface IProps {
  card: ICardMini;
  className?: string;
  onClick?: () => void;
  disable?: boolean;
  onClickFavourite?: () => void;
  onClickAddToCart?: () => void;
  isAccompany?: boolean;
}
const CardMini: FC<IProps> = props => {
  const {
    card,
    className,
    onClick,
    disable = false,
    onClickFavourite,
    onClickAddToCart,
    isAccompany
  } = props;
  const [allStars, setAllStars] = useState<boolean>(true);
  const isInCart = false;
  const cardRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const MainContent = ({
    children
  }: {
    children: ReactNode;
  }) => {
    return isAccompany ? <Link prefetch={false} href={`/card/${card.id}/`} className={clsx(styles.wrap, isInCart && styles.inCart, styles.wrapHorizontal, className)} aria-disabled={disable} title={card.name} onClick={handleClick} data-sentry-element="Link" data-sentry-component="MainContent" data-sentry-source-file="index.tsx">
        {children}
      </Link> : <div className={clsx(styles.wrap, isInCart && styles.inCart, className)} aria-disabled={disable} data-sentry-component="MainContent" data-sentry-source-file="index.tsx">
        {children}
      </div>;
  };
  const ImageBlock = ({
    children
  }: {
    children: ReactNode;
  }) => {
    return isAccompany ? <>{children}</> : <Link prefetch={false} href={`/card/${card.id}/`} className={styles.link} onClick={handleClick} title={card.name} data-sentry-element="Link" data-sentry-component="ImageBlock" data-sentry-source-file="index.tsx">
        {children}
      </Link>;
  };
  const PriceBlock = ({
    children
  }: {
    children: ReactNode;
  }) => {
    return isAccompany ? <div className={styles.info} data-sentry-component="PriceBlock" data-sentry-source-file="index.tsx">{children}</div> : <>{children}</>;
  };
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  const updateStars = () => {
    if (cardRef.current) {
      setAllStars(cardRef.current.clientWidth > 180);
    }
  };
  useEffect(() => {
    if (cardRef.current) {
      updateStars();
      window.addEventListener("resize", updateStars);
    }
    return () => {
      window.removeEventListener("resize", updateStars);
    };
  }, [cardRef.current]);
  const handleCommission = () => {
    if (!card.comission) return;
    router.push(`/card/${card.id}`);
  };
  return <MainContent data-sentry-element="MainContent" data-sentry-component="CardMini" data-sentry-source-file="index.tsx">
      {!isAccompany && <Actions card={card} onClickFavourite={onClickFavourite} />}
      <ImageBlock data-sentry-element="ImageBlock" data-sentry-source-file="index.tsx">
        <div ref={cardRef} className={clsx(styles.image_wrap)}>
          {!isAccompany && <Labels card={card} />}
          <Image className={card.comission ? styles.comissionImg : styles.img} alt={card.name} src={li(card.preview_picture)} width={500} height={500} itemProp="image" loading={"eager"} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </div>
        {!card.comission && !isAccompany ? <div className={styles.reviews}>
            <Stars stars={card.reviews} isAllStars={allStars} />
            {card.reviews_count && card.reviews_count != 0 && <div className={styles.reviewsNumbers}>
                <p className={styles.reviewsRating}>{card.reviews}</p>
                <p className={styles.reviewsCount}>{card.reviews_count}</p>
              </div>}
          </div> : null}
        {!isAccompany && <p className={styles.name}>{decodeString(card.name)}</p>}
      </ImageBlock>
      <PriceBlock data-sentry-element="PriceBlock" data-sentry-source-file="index.tsx">
        {isAccompany && <p className={styles.name}>{decodeString(card.name)}</p>}
        <div className={`${styles.bottom} ${card.comission && styles.comission}`} onClick={handleCommission}>
          <Price card={card} data-sentry-element="Price" data-sentry-source-file="index.tsx" />
          <Buttons card={card} onClickAddToCart={onClickAddToCart} data-sentry-element="Buttons" data-sentry-source-file="index.tsx" />
        </div>
      </PriceBlock>
    </MainContent>;
};
export default CardMini;