import { ICardMini } from "@/shared/types/types";
import styles from "./index.module.scss";
import { FC } from "react";
import { priceWithSpaces } from "@/utils/functions/price-with-spaces";
import clsx from "clsx";
interface IPrice {
  card: ICardMini;
}
const Price: FC<IPrice> = props => {
  const {
    card
  } = props;
  return <div className={styles.priceWrap} data-sentry-component="Price" data-sentry-source-file="index.tsx">
      {card.price_discount !== card.price ? <>
          {!card.comission && <p className={styles.priceOld}>{priceWithSpaces(card.price)}</p>}
          {card.comission && <p className={styles.price}>{priceWithSpaces(card.price)}</p>}
          {card.price_discount && <p className={styles.price}>{priceWithSpaces(card.price_discount)}</p>}
        </> : <p className={clsx(styles.price, card.comission && styles.priceComission)}>
          {priceWithSpaces(card.price_discount)}
        </p>}
    </div>;
};
export default Price;